<template>
  <el-select
    v-bind="{ ...$attrs, ...props }"
    :class="[
      'ui-select',
      {
        'ui-select--filled': !!filled,
        'ui-select--clearable': !!props.clearable,
      },
    ]"
    effect="dark"
    :clear-icon="CloseIcon"
    :suffix-icon="ArrowDownIcon"
  >
    <template #prefix>
      <slot name="prefix"></slot>
    </template>
    <slot name="default"></slot>
  </el-select>
</template>
<script setup lang="ts">
import { SelectProps } from 'element-plus/es/components/select/src/select'
import CloseIcon from '@base/assets/img/svg/icon-close.svg'
import ArrowDownIcon from '@base/assets/img/svg/icon-arrow-down.svg'

const props = defineProps({
  ...SelectProps,
  /**
   * Whether the select should be filled with a background color.
   * @default false
   */
  filled: {
    type: Boolean,
    default: false,
  },
  /**
   * Override the default teleported prop value
   */
  teleported: {
    type: Boolean,
    default: false,
  },
})
</script>
<style lang="postcss" scoped>
/* stylelint-disable plugin/no-unsupported-browser-features */
.ui-select.el-select {
  --ui-text-color: var(--brand-color-text-tertiary);
  --ui-text-color-selected: var(--brand-color-text-secondary);
  --ui-select-transition-duration: 300ms;

  --el-select-font-size: 1rem;
  --el-select-close-hover-color: var(--brand-color-text-primary);
  --el-select-input-color: var(--ui-text-color);
  --el-select-multiple-input-color: var(--brand-color-text-primary);
  --el-select-input-font-size: 1.5rem;
  --el-border-radius-base: 1.25rem;
  --el-fill-color-blank: transparent;
  --el-text-color-regular: var(--brand-color-text-primary);

  &:deep(.el-select__wrapper.is-disabled) {
    background-color: var(--el-fill-color-blank);
  }
}
.ui-select.el-select.ui-select--filled {
  --el-fill-color-blank: var(--brand-color-base-tertiary);
  &:deep(.el-select__wrapper) {
    padding: 0.25rem 1.25rem; /* 4px 20px */
  }
}
.ui-select.el-select:hover {
  --ui-text-color: var(--brand-color-text-secondary);
}
.ui-select.el-select:deep(.el-select__caret) {
  transition-duration: var(--ui-select-transition-duration);
}
.ui-select.el-select:has(.el-select-dropdown__item.is-selected) {
  --ui-text-color: var(--ui-text-color-selected);
  &:hover:deep(.el-select__icon) {
    color: var(--brand-color-text-tertiary);
  }
  &:deep(.el-select__icon) {
    color: var(--brand-color-text-secondary);
    &:hover {
      color: var(--brand-color-text-secondary);
    }
  }
  &:not(.ui-select--clearable):hover:deep(.el-select__icon) {
    color: var(--brand-color-text-secondary);
  }
}
.ui-select.el-select:has(.el-select__wrapper.is-focused) {
  --ui-text-color: var(--brand-color-text-secondary);
  &.ui-select--filled:deep(.el-select__wrapper) {
    outline: 1px solid var(--brand-color-text-secondary);
  }
}
.ui-select.el-select:deep(.el-select__wrapper) {
  gap: 0;
  padding: 0.25rem 0; /* 4px 0 */
  font-size: 1rem; /* 16px */
  font-weight: 600;
  line-height: 1.375rem; /* 22px */
  border: none;
  box-shadow: none;
}
.ui-select.el-select:deep(.el-select__placeholder) {
  padding-right: 0.5rem; /* 8px */
  font-family: var(--font-family-secondary);
  font-size: 1rem; /* 16px */
  font-weight: 600;
  line-height: 1.375rem; /* 22px */
  color: var(--el-select-input-color);
  transition-duration: var(--ui-select-transition-duration);
}
.ui-select.el-select:deep(.el-select__popper.el-popper),
.ui-select.el-select:deep(.el-select__popper.el-popper.is-dark) {
  --el-bg-color-overlay: var(--brand-color-base-secondary);
  --el-border-color-light: var(--brand-color-base-tertiary);
  --el-box-shadow-light: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
  --el-popper-border-radius: 1.5rem; /* 24px */
  --el-fill-color-light: var(--brand-color-base-tertiary);

  font-family: var(--font-family-secondary);
  font-size: 1rem; /* 16px */
  font-weight: 600;
  line-height: 1.375rem; /* 22px */
  color: var(--brand-color-text-secondary);
  background: var(--el-bg-color-overlay);
  border: 1px solid var(--el-border-color-light);
}
.ui-select.el-select:deep(.el-select-dropdown__list) {
  display: flex;
  flex-direction: column;
  gap: 0.75rem; /* 12px */
  padding: 0.75rem; /* 12px */
}
.ui-select.el-select:deep(
    .el-select-dropdown__list:has(.el-select-group__wrap)
  ) {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.75rem; /* 12px */
  row-gap: 3.75rem; /* 60px */
  align-items: stretch;
  padding: 1.25rem; /* 20px */

  @media screen and (max-width: 1279px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 388px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.ui-select.el-select:deep(.el-popper__arrow) {
  display: none;
}
.ui-select.el-select:deep(.el-popper__arrow::before) {
  background: var(--brand-color-base-secondary);
  border: 1px solid var(--brand-color-base-tertiary);
}

@media screen and (max-width: 767px) {
  .ui-select.el-select:deep(.el-select__popper.el-popper),
  .ui-select.el-select:deep(.el-select__popper.el-popper.is-dark) {
    width: calc(100vw - 2rem);
  }
}
</style>
